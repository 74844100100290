exports.components = {
  "component---src-templates-business-blog-category-js": () => import("./../../../src/templates/businessBlog/category.js" /* webpackChunkName: "component---src-templates-business-blog-category-js" */),
  "component---src-templates-business-blog-category-womeninbusiness-js": () => import("./../../../src/templates/businessBlog/category-womeninbusiness.js" /* webpackChunkName: "component---src-templates-business-blog-category-womeninbusiness-js" */),
  "component---src-templates-business-blog-page-js": () => import("./../../../src/templates/businessBlog/page.js" /* webpackChunkName: "component---src-templates-business-blog-page-js" */),
  "component---src-templates-business-blog-single-js": () => import("./../../../src/templates/businessBlog/single.js" /* webpackChunkName: "component---src-templates-business-blog-single-js" */),
  "component---src-templates-business-blog-single-womeninbusiness-js": () => import("./../../../src/templates/businessBlog/single-womeninbusiness.js" /* webpackChunkName: "component---src-templates-business-blog-single-womeninbusiness-js" */),
  "component---src-templates-business-blog-tag-js": () => import("./../../../src/templates/businessBlog/tag.js" /* webpackChunkName: "component---src-templates-business-blog-tag-js" */),
  "component---src-templates-contacts-page-js": () => import("./../../../src/templates/contacts/page.js" /* webpackChunkName: "component---src-templates-contacts-page-js" */),
  "component---src-templates-credit-line-page-js": () => import("./../../../src/templates/creditLine/page.js" /* webpackChunkName: "component---src-templates-credit-line-page-js" */),
  "component---src-templates-green-finance-page-js": () => import("./../../../src/templates/greenFinance/page.js" /* webpackChunkName: "component---src-templates-green-finance-page-js" */),
  "component---src-templates-how-return-page-inner-instruction-js": () => import("./../../../src/templates/howReturn/page-inner-instruction.js" /* webpackChunkName: "component---src-templates-how-return-page-inner-instruction-js" */),
  "component---src-templates-how-return-page-js": () => import("./../../../src/templates/howReturn/page.js" /* webpackChunkName: "component---src-templates-how-return-page-js" */),
  "component---src-templates-main-page-js": () => import("./../../../src/templates/main/page.js" /* webpackChunkName: "component---src-templates-main-page-js" */),
  "component---src-templates-map-page-js": () => import("./../../../src/templates/map/page.js" /* webpackChunkName: "component---src-templates-map-page-js" */),
  "component---src-templates-news-category-js": () => import("./../../../src/templates/news/category.js" /* webpackChunkName: "component---src-templates-news-category-js" */),
  "component---src-templates-news-single-js": () => import("./../../../src/templates/news/single.js" /* webpackChunkName: "component---src-templates-news-single-js" */),
  "component---src-templates-products-page-js": () => import("./../../../src/templates/products/page.js" /* webpackChunkName: "component---src-templates-products-page-js" */),
  "component---src-templates-sitemap-page-js": () => import("./../../../src/templates/sitemap/page.js" /* webpackChunkName: "component---src-templates-sitemap-page-js" */),
  "component---src-templates-smart-card-page-inner-description-js": () => import("./../../../src/templates/smartCard/page-inner-description.js" /* webpackChunkName: "component---src-templates-smart-card-page-inner-description-js" */),
  "component---src-templates-smart-card-page-inner-preliminary-js": () => import("./../../../src/templates/smartCard/page-inner-preliminary.js" /* webpackChunkName: "component---src-templates-smart-card-page-inner-preliminary-js" */),
  "component---src-templates-smart-card-page-inner-qr-js": () => import("./../../../src/templates/smartCard/page-inner-qr.js" /* webpackChunkName: "component---src-templates-smart-card-page-inner-qr-js" */),
  "component---src-templates-smart-card-page-inner-steps-js": () => import("./../../../src/templates/smartCard/page-inner-steps.js" /* webpackChunkName: "component---src-templates-smart-card-page-inner-steps-js" */),
  "component---src-templates-smart-card-page-js": () => import("./../../../src/templates/smartCard/page.js" /* webpackChunkName: "component---src-templates-smart-card-page-js" */)
}

